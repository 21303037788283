<template>
  <template class="flex flex-col gap-4">
    <section class="box p-4 flex justify-between">
      <div class="flex flex-col">
        <span class="font-bold">Numero de radicado:</span>
        <span class="text-lg">{{ getProcesoJudicial.numeroRadicado }}</span>
      </div>

      <div class="flex flex-col">
        <span class="font-bold">Información del juzgado:</span>
        <span class="text-lg">{{ getProcesoJudicial.juzgadoProcesosJudiciales.nombre }}</span>
      </div>

      <div class="flex flex-col justify-center gap-2">
        <div class="flex items-center gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-600 p-1 rounded-full" viewBox="0 0 20 20"
               fill="currentColor">
            <path fill-rule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clip-rule="evenodd"/>
          </svg>
          <span class="font-bold">Fecha Reparto: </span>
          <span>{{ $h.formatDate(getProcesoJudicial.fechaReparto) }}</span>
        </div>
        <div class="flex items-center gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-600 p-1 rounded-full" viewBox="0 0 20 20"
               fill="currentColor">
            <path fill-rule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clip-rule="evenodd"/>
          </svg>
          <span class="font-bold">Fecha Notificación: </span>
          <span>{{ $h.formatDate(getProcesoJudicial.fechaNotificacion) }}</span>
        </div>
      </div>
    </section>
    <section class="box p-4 flex flex-col gap-4">
      <DataTable :value="listProcesosJucicialesActuaciones.rows"
                 class="p-datatable-customers p-datatable-sm text-sm"
                 showGridlines
                 dataKey="id"
                 v-model:filters="filters1"
                 filterDisplay="menu"
                 responsiveLayout="scroll"
                 @sort="sort"
                 removableSort
      >
        <template #header>
          <div class="flex justify-end">
            <div class="flex gap-4">
<!--              <Button type="button"-->
<!--                      icon="pi pi-filter-slash text-sm"-->
<!--                      label="Limpiar filtros"-->
<!--                      class="p-button-outlined text-sm font-medium"-->
<!--                      @click="clearFilter1()"-->
<!--              />-->
              <Button label="Nuevo" icon="pi pi-plus" class="bg-blue-900" @click="$router.push({ name: 'pharmasan.administrativa.juridica.procesos-judiciales.ver.actuaciones.crear'})"/>
            </div>
          </div>
        </template>
        <template #empty>
          No hay datos para mostrar
        </template>

        <Column headerStyle="width: 3rem" style="text-align: center; justify-content: center;" field="id" header="Id"
                class="text-xs"/>

        <Column field="anotacion" header="Actuación" style="min-width:8rem" class="text-xs"/>

        <Column field="observaciones" header="Observaciones" style="min-width:8rem" class="text-xs"/>

        <Column field="fechaIniciaTermino" header="Fecha Inicia Termino" style="min-width:8rem" class="text-xs">
          <template #body="{data}">
            {{ data.fechaIniciaTermino }}
          </template>
        </Column>

        <Column field="fechaFinalizaTermino" header="Fecha Finaliza Termino" style="min-width:8rem" class="text-xs">
          <template #body="{data}">
            {{ data.fechaFinalizaTermino }}
          </template>
        </Column>

        <Column field="fechaActuacion" header="Fecha Actuación" style="min-width:8rem" class="text-xs">
          <template #body="{data}">
            {{ $h.formatDate(data.fechaActuacion) }}
          </template>
        </Column>

        <Column field="createdAt" header="Fecha Registro" style="min-width:8rem" class="text-xs">
          <template #body="{data}">
            {{ $h.formatDate(data.createdAt, 'YYYY-MM-DD HH:mm') }}
          </template>
        </Column>

        <Column header="Acciones">
          <template #body="{data}">
            <div class="flex gap-4 justify-center" style="text-align: center; justify-content: center;">
              <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-warning" @click="openModal(data)" v-if="data.paths.length > 0"/>
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-help"
                      @click="$router.push({ name: 'pharmasan.administrativa.juridica.procesos-judiciales.ver.actuaciones.actualizar', params: { idActuacion: data.id}})" />
            </div>
          </template>
        </Column>
      </DataTable>
      <Paginator v-model:first="params.offset"
                 :rows="params.limit"
                 :totalRecords="listProcesosJucicialesActuaciones.count"
                 :rowsPerPageOptions="[2,10,20,30]"
                 @page="onPage($event)"
      />
    </section>
  </template>
  <Dialog header="Adjuntos" v-model:visible="displayModal" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}" :modal="true">
    <div v-for="(adjunto, i) in adjuntos" :key="i">
      <div class="flex justify-between">
        <div>
          <p>
            {{ adjunto.fileName }}
          </p>
        </div>
        <div>
          <button @click="verPdf(adjunto.urlPath)">
            <i class="pi pi-eye text-blue-600"></i>
          </button>
        </div>
      </div>
    </div>
    <template #footer>
      <!-- <Button label="No" icon="pi pi-times" @click="closeModal" class="p-button-text" /> -->
      <Button label="OK" @click="closeModal" autofocus />
    </template>
  </Dialog>
</template>

<script>
import { onMounted, computed, ref } from 'vue'
import ProcesosJudicialesStore from '@/apps/pharmasan/juridica/procesosJuridicos/store/procesosJudiciales.store'
import { FilterMatchMode } from 'primevue/api'
import { useRoute } from 'vue-router'
import PdfService from '@/apps/pharmasan/juridica/procesosJuridicos/services/pdf.service'

export default {
  name: 'actuaciones',
  setup () {
    const _PdfService = new PdfService()
    const route = useRoute()
    const getProcesoJudicial = computed(() => ProcesosJudicialesStore.getters.getProcesoJudicial)
    const listProcesosJucicialesActuaciones = computed(() => ProcesosJudicialesStore.getters.getListActuaciones)
    const params = computed(() => {
      return {
        offset: offset.value,
        page: page.value,
        limit: limit.value,
        filters: filters1.value
      }
    })
    const adjuntos = ref([])
    const displayModal = ref(false)

    const offset = ref(0)
    const limit = ref(10)
    const page = ref(0)
    const filters1 = ref({
      procesoJudicialId: {
        value: route.params.id,
        matchMode: FilterMatchMode.EQUALS
      }
    })

    const clearFilter1 = () => {
      filters1.value = {
        procesoJudicialId: {
          value: null,
          matchMode: FilterMatchMode.EQUALS
        }
      }
    }
    const sort = (evt) => {
      console.log({ evt })
    }
    const onPage = ({ first, page, pageCount, rows }) => {
      params.value.offset = first
      params.value.page = page
      ProcesosJudicialesStore.dispatch('actionActuaciones', params.value)
    }
    const verPdf = (path) => {
      _PdfService.ver({ path })
    }
    const openModal = ({ paths }) => {
      adjuntos.value = []
      adjuntos.value = paths
      displayModal.value = true
    }
    const closeModal = () => {
      displayModal.value = false
    }

    onMounted(() => {
      ProcesosJudicialesStore.dispatch('actionActuaciones', params.value)
      ProcesosJudicialesStore.dispatch('actionProcesoJudicial', route.params.id)
    })

    return {
      filters1,
      listProcesosJucicialesActuaciones,
      params,
      displayModal,
      adjuntos,
      getProcesoJudicial,
      sort,
      onPage,
      clearFilter1,
      verPdf,
      openModal,
      closeModal
    }
  }
}
</script>

<style scoped>

</style>
